import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from 'gatsby-image'
import Carousel from 'nuka-carousel'
import BackgroundImage from 'gatsby-background-image'

export default ({ data }) => {
  const post = data.nodeEvent
  const dataImgs = data.nodeEvent.relationships.field_event_image

  return (
    <Layout>
    <div id="fh5co-product">
	<div class="container">
	<div class="row">
	<div class="col-md-10 col-md-offset-1 animate-box fadeInUp animated-fast">
      
        <h1>{ post.title }</h1>

        <div style={{ maxWidth: `400px`, marginBottom: `1.45rem`, width: `100%` }}>   
			<Img fluid={post.relationships.field_event_image.localFile.childImageSharp.fluid} />	
        </div>  
        
        <p>

        <div dangerouslySetInnerHTML={{ __html: post.body.value }}></div>

        </p>
          
		
      </div>
      </div>
      </div>
      </div>
    
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    nodeEvent(id: { eq: $id }) {
      id
      title
      body {
        summary
        value
      }
      created
      relationships { 
          field_event_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
                src
                }
              }
            } 
        }   
      }
    }
  }
`




